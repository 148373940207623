'use strict'

import initSlider from "../components/slider.js"

const initHomepage = () => {
    const homepage = document.getElementById('homepage')

    if (!homepage) return

    const swiperElements = homepage.querySelectorAll('.swiper.swiper-homepage');

    initSlider(swiperElements, {
        speed: 900,
        breakpoints: {
            991: {
                slidesPerView: 'auto',
            }
        },
        slidesPerView: 1,
    })
}

export default initHomepage