'use strict'
import initSlider from "../components/slider.js"
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

const initAutodetailing = () => {
    const autodetailing = document.getElementById('autodetailing')

    if (!autodetailing) return

    /*
    * bind na všechny call to action
    * po kliknutí scroll na formulář s vybranou variantou
    * */
    const callToAction = autodetailing.querySelectorAll('.autodetailing-call-to-action');

    callToAction.forEach(button => {
        button.addEventListener('click', function (event) {
            event.preventDefault();

            const serviceValue = this.getAttribute('data-value');

            const form = document.querySelector('.reservation-form');
            const serviceSelect = form.querySelector('select[name="offer"]');

            serviceSelect.value = serviceValue;

            form.scrollIntoView({ behavior: 'smooth' });
        });
    });

    /*
    * init na swiper
    * inicializuje na viewport
    * */
    const swiperElements = autodetailing.querySelectorAll('.swiper.swiper-autodetailing');

    initSlider(swiperElements, {
        slidesPerView: 1,
        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            991: {
                slidesPerView: 4,
            }
        }
    })

    /*
    * init na lightgallery
    * */
    const galleryContainer = autodetailing.querySelector('.autodetailing-gallery-container');
    if (galleryContainer) {
        lightGallery(galleryContainer, {
            selector: 'a',
            plugins: [lgThumbnail],
            thumbnail: true,
            zoom: true,
        });
    }
}

export default initAutodetailing