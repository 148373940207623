const lazyInit = (element, fn) => {
    const observer = new IntersectionObserver((entries) => {
        if (entries.some( ({isIntersecting}) => isIntersecting )) {
            observer.disconnect()
            fn()
        }
    }, { rootMargin: "0px 0px 60% 0px" })
    observer.observe(element);
}

export {lazyInit}