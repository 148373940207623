const toggleErrorClass = (element, hasError) => {
    element.classList.toggle('error', hasError);
};

const validateElement = (element) => {
    const value = element.value.trim();
    let isValid = true;

    toggleErrorClass(element, false);

    if (element.type !== 'submit' && !value) {
        isValid = false;
    } else if (element.type === 'email' && !/\S+@\S+\.\S+/.test(value)) {
        isValid = false;
    } else if (element.type === 'tel' && !/^\d+$/.test(value)) {
        isValid = false;
    }

    toggleErrorClass(element, !isValid);
    return isValid;
};

const addFocusListener = (element) => {
    if (!element.hasFocusListener) {
        element.addEventListener('focus', () => {
            toggleErrorClass(element, false);
        });
        element.hasFocusListener = true;
    }
};

const validateData = (elements) => {
    let isValid = true;

    for (let element of elements) {
        if (['INPUT', 'SELECT'].includes(element.tagName)) {
            isValid = validateElement(element) && isValid;
            addFocusListener(element);
        }
    }

    return isValid;
};

export function sendForm() {
    const form = document.querySelector('.reservation-form');
    const submitButton = form.querySelector('button[type="submit"]');
    const elements = form.elements;

    form.addEventListener('submit', (e) => {
        e.preventDefault();
        submitButton.classList.add('loading');

        if (validateData(elements)) {
            const formData = new FormData(form);

            fetch(form.action, {
                method: form.method,
                body: formData
            })
                .then(response => {
                    submitButton.classList.remove('loading');
                    if (!response.ok) {
                        throw new Error('Network response was not ok.');
                    } else {
                        fbq('track', 'Lead')
                        form.reset();
                        alert('Děkujeme za váš zájem. Naši pracovníci již o vaši rezervaci vědí a budou vás v nejbližší době kontaktovat. S pozdravem tým Mad Max Detailing');
                    }
                })
                .catch(error => {
                    submitButton.classList.remove('loading');
                    alert('Při odeslání došlo k chybě. Zkuste prosím vyplnit formulář znovu nebo nás kontaktujte na telefonním čísle +420 778 028 968');
                });
        } else {
            submitButton.classList.remove('loading');
        }
    });
}
