import { lazyInit } from "../mixins";
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const initSlider = (swiperElements, settings) => {
    if (!swiperElements) return;

    swiperElements.forEach(swiperElement => {
        lazyInit(swiperElement, () => {
            swiperElement.classList.remove('swiper-no-init');
            new Swiper(swiperElement, {
                navigation: {
                    nextEl: swiperElement.querySelector('.swiper-button-next'),
                    prevEl: swiperElement.querySelector('.swiper-button-prev'),
                },
                speed: 900,
                ...settings
            });
        });
    });
}

export default initSlider;
