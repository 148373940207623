'use strict'

import '../scss/main.scss'
import './webpack.hmr.js'
import initHomepage from "./pages/homepage.js"
import {sendForm} from "./components/form";
import {navigation} from "./components/navigation";
import initAutodetailing from "./pages/autodetailing.js"

window.addEventListener("DOMContentLoaded", () => {
    sendForm()
    navigation()

    initAutodetailing()
    initHomepage()
});
